import React, { useRef, useEffect, useState } from 'react';
import SimplePeer from 'simple-peer';

function WebRTCComponent() {
    const [yourID, setYourID] = useState("");
    const [partnerID, setPartnerID] = useState("");
    const yourVideo = useRef();
    const partnerVideo = useRef();
    const socket = useRef(null);
    const peerRef = useRef(null);
    const videoStream = useRef(null);
    const signalTypesStringToNumber = {
        "offer": 0,
        "pranswer": 1,
        "answer": 2,
        "rollback": 3
    };
    const signalTypesNumberToString = {
        0: "offer",
        1: "pranswer",
        2: "answer",
        3: "rollback"
    };

    useEffect(() => {

        // ask for video and audio streams
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
                sampleRate: 44100,
                channelCount: 2
            }
        }).then(stream => {
            videoStream.current = stream;
            if (yourVideo.current) {
                
                // extract the video stream
                const videoTrack = stream.getVideoTracks()[0];
                const videoOnlyStream = new MediaStream([videoTrack]);

                // show the video stream in "yourVideo"
                yourVideo.current.srcObject = videoOnlyStream;
            }
        });

        // generate a random peer ID
        const id = Math.floor(Math.random() * 10000).toString();
        setYourID(id);

        // connect to the web socket using environment variable
        const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8080";
        socket.current = new WebSocket(wsUrl);

        console.log(`Connecting to websocket URL ${wsUrl}`);

        // handle messages that come from the web socket
        socket.current.onmessage = didReceiveMessageFromWebSocket;

        // handler that is running when the socket connection was established
        socket.current.onopen = () => webSocketDidOpen(id);

        socket.current.onerror = (error) => {
            console.error('WebSocket error:', error);
            peerRef.current = null;
        };
        socket.current.onclose = () => {
            console.log('WebSocket connection closed.');
            peerRef.current = null;
        };

    }, []);

    // ----- Button actions -----

    function onCallButtonTapped(partnerID) {
        setupPeerIfNecessary(yourID, partnerID, true);
    };

    // ----- WebSocket handlers -----

    function webSocketDidOpen(id) {

        // make sure the websocket is open
        if (socket.current.readyState != WebSocket.OPEN) {
            return;
        }

        console.log(`Websocket connection is now open.`);

        // send the register message with our peer ID
        socket.current.send(JSON.stringify({ type: "register", id: id }));

        // send keep-alive messages
        socket.current.keepAliveInterval = setInterval(() => {
            socket.current.send(JSON.stringify({ type: "keep-alive" }));
        }, 50000); // 50 seconds
    };

    function didReceiveMessageFromWebSocket(message) {

        const parsedMessage = JSON.parse(message.data);

        if (parsedMessage.type === "signal") {
            didReceiveSignal(parsedMessage);
        } else if (parsedMessage.type === "ice-candidate") {
            didReceiveICECandidate(parsedMessage.candidate);
        }
    };

    function didReceiveSignal(data) {

        console.log(`did receive signal`);

        const decodedSignalStr = atob(data.signal);
        const decodedSignal = JSON.parse(decodedSignalStr);

        decodedSignal.type = signalTypesNumberToString[decodedSignal.type];

        setupPeerIfNecessary(data.to, data.from, false);

        peerRef.current.signal(decodedSignal);
    };

    function didReceiveICECandidate(candidate) {
        console.log(`did receive icecandidate`);

        const decodedCandidate = {
            type: "candidate",
            candidate: JSON.parse(atob(candidate))
        };

        peerRef.current.signal(decodedCandidate);
    };

    function setupPeerIfNecessary(yourID, partnerID, isInitiator) {

        if (peerRef.current !== null) {
            return;
        }

        // generate a peer object
        const peer = new SimplePeer({
            initiator: isInitiator,
            trickle: true,
            stream: videoStream.current,
            config: {
                iceServers: [
                    { urls: 'stun:185.208.206.119:3478' },
                    {
                        urls: 'turn:185.208.206.119:3478',
                        username: 'reactuser',
                        credential: 'd9QAXhWWEjBTk27iU2YsfAcRDL4yDuu3o5sEUr8hXs6Ztkz2uu'
                    }
                ]
            }
        });

        // listen for signal events
        peer.on('signal', signal => {
            if (signal.type === "candidate") { // ICE candidate
                console.log(`sending icecandidate to ${partnerID}`);
                const encodedCandidate = btoa(JSON.stringify(signal.candidate));
                socket.current.send(JSON.stringify({
                    type: 'ice-candidate',
                    candidate: encodedCandidate,
                    from: yourID,
                    to: partnerID
                }));
            } else if (signal.type === "transceiverRequest") { // transceiverRequest
                console.log(`sending transceiverRequest to ${partnerID}`);
                const encodedSignal = btoa(JSON.stringify(signal));
                socket.current.send(JSON.stringify({
                    type: "transceiverRequest",
                    request: encodedSignal,
                    from: yourID,
                    to: partnerID
                }));
            } else { // signal
                console.log(`sending signal to ${partnerID} of type ${signal.type}`);
                signal.type = signalTypesStringToNumber[signal.type];
                const encodedSignal = btoa(JSON.stringify(signal));
                socket.current.send(JSON.stringify({
                    type: "signal",
                    signal: encodedSignal,
                    from: yourID,
                    to: partnerID
                }));
            }
        });

        // listen for stream events
        peer.on('stream', stream => {
            console.log(`received stream from peer`);
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        peer.on('connect', () => {
            console.log(`sucessfully connected to peer`);
        });

        peer.on('close', () => {
            console.log('Peer connection closed');
            peerRef.current = null;
        });

        peer.on('error', error => {
            console.log(`error - ${error}`);
            peerRef.current = null;
        });

        peerRef.current = peer;

        console.log('Created a new peer.');
    };

    return (
        <div>
            <video ref={yourVideo} autoPlay playsInline />
            <video ref={partnerVideo} autoPlay playsInline />
            <input type="text" value={partnerID} onChange={(e) => setPartnerID(e.target.value)} placeholder="Partner ID" />
            <button onClick={() => onCallButtonTapped(partnerID)}>Call</button>
            <p>Your ID: {yourID}</p>
        </div>
    );
}

export default WebRTCComponent;